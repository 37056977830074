import * as styles from './modal.module.scss'

import { graphql, useStaticQuery } from 'gatsby'

import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

const PopupModal = ({ open }) => {
  const { image } = useStaticQuery(graphql`
    {
      image: file(relativePath: {eq: "popup-kochbuch-cover.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            quality: 65
            width: 323
          )
        }
      }
    }
  `)

  return (
    <div className={styles.modal} data-open={open}>
      <div className={styles.title}>Unser Kochbuch kaufen!</div>
      <div className={styles.body}>In unserem Kochbuch findest du 101 vegane Rezepte für jeden Tag – egal ob Frühstück am Wochenende, Mittagspause im Büro oder ein Abendessen mit Freund*innen.</div>
      <div className={styles.image}>
        <GatsbyImage
          image={image?.childImageSharp?.gatsbyImageData}
          alt="Unser Adventskalender ist zurück!"
          style={{ maxWidth: "100%" }}
        />
      </div>
      <a target='_blank' className={styles.button} href="https://shop.zuckerjagdwurst.com/products/kochbuch-vegan-durch-den-tag-vorbestellung">Jetzt bestellen</a>
    </div>
  )
}

export default PopupModal
